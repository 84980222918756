import React from 'react';

type InputLabelProps = {
  label: React.ReactNode;
  id: string;
  required?: boolean;
  error?: string;
  inline?: boolean;
  children: React.ReactElement;
};

const InputBase = ({
  label,
  id,
  required,
  error,
  inline,
  children,
}: InputLabelProps) => {
  let child = children;
  if (error) {
    child = React.cloneElement(children, {
      className: `${children.props.className} border-error`,
    });
  }
  return (
    <div
      data-testid="email-container"
      className={`flex-col w-full ${inline ? 'inline-flex' : 'flex'}`}
    >
      <div>
        <label
          className="block mb-1 md:text-base text-sm font-medium"
          htmlFor={id}
        >
          {label}
          {required && <span className="text-error"> *</span>}
        </label>
        {child}
        {error && <p className="text-error mt-1 md:text-sm text-xs">{error}</p>}
      </div>
    </div>
  );
};

export default InputBase;
