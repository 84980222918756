import { useEffect, useState } from 'react';

interface ReCaptchaWindow extends Window {
  grecaptcha: any;
}

declare const window: ReCaptchaWindow;

const useReCaptcha = () => {
  const [token, setToken] = useState('');

  const refreshToken = () => {
    window.grecaptcha.ready(async () => {
      const t = await window.grecaptcha.execute(
        process.env.GATSBY_RECAPTCHA_PUB_KEY,
        { action: 'submit' },
      );
      setToken(t);
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_PUB_KEY}`;
    script.addEventListener('load', refreshToken);
    document.body.appendChild(script);

    return () => {
      script.remove();
      document.getElementsByClassName('grecaptcha-badge')[0].remove();
    };
  }, []);

  return { token, refreshToken };
};

export default useReCaptcha;
