import React, { useState } from 'react';
import classNames from 'classnames';
import { contactContent } from '../../content/index';
import useReCaptcha from '../../hooks/useReCaptcha';
import InputBase from '../InputBase';
import Input from '../Input';
import Button from '../Button';

const buttonClass = classNames(
  'md:px-4 px-19px py-15px w-3/4 mx-auto block mt-6',
  'border border-black rounded',
  'bg-secondary pointer-fine:hover:bg-white',
  'border-opacity-0 pointer-fine:hover:border-opacity-100',
  'text-white pointer-fine:hover:text-black',
  'transform duration-200 ease-in group',
  'font-medium text-md',
);

const required = ['firstName', 'lastName', 'email', 'company'];

const validateField = (name: string, value: string) => {
  if (!value && required.includes(name)) {
    return 'This field is required';
  }

  if (name === 'email' && value && !value.match(/^\S+@\S+\.\S+$/)) {
    return 'Email is formatted incorrectly';
  }

  if ((name === 'firstName' || name === 'lastName') && value) {
    const cleanedName = value.trim().replace(/\s+/g, ' ');
    if (
      /[$!%<>]/.test(value) ||
      /\s{2,}/.test(value) ||
      !/^(?=.*[a-zA-Z])[a-zA-Z'’\-. ]+$/.test(cleanedName)
    ) {
      return /\s{2,}/.test(value)
        ? 'Please use only single space'
        : `Please enter a valid ${
            name === 'firstName' ? 'first' : 'last'
          } name`;
    }
  }

  if (name === 'company' && value) {
    const cleanedCompany = value.trim().replace(/\s+/g, ' ');
    if (
      /[$%<>]/.test(value) ||
      /\s{2,}/.test(value) ||
      !/^(?=.*[a-zA-Z0-9])[a-zA-Z0-9'’&\-!. ]+$/.test(cleanedCompany)
    ) {
      return /\s{2,}/.test(value)
        ? 'Please use only single space'
        : 'Please enter a valid company name';
    }
  }

  return '';
};

function ContactForm() {
  const {
    header,
    description,
    groups: {
      contact: { header: contactHeader, inputs: contactInputs },
      inquiry: { header: inquiryHeader, inputs: inquiryInputs },
    },
  } = contactContent.form;

  const { token, refreshToken } = useReCaptcha();
  const [selectChanged, setSelectChanged] = useState(false);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    interest: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    interest: '',
    message: '',
  });
  const [formResult, setFormResult] = useState({
    type: '',
    message: '',
  });

  const onFieldChange = <
    T extends HTMLInputElement & HTMLTextAreaElement & HTMLSelectElement,
  >(
    event: React.ChangeEvent<T>,
  ) => {
    if (event.target.name === 'interest' && !selectChanged) {
      setSelectChanged(true);
    }
    setForm(prevVal => ({
      ...prevVal,
      [event.target.name]: event.target.value,
    }));

    setErrors(prevErrors => ({
      ...prevErrors,
      [event.target.name]: validateField(event.target.name, event.target.value),
    }));
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const foundErrors = {
      firstName: validateField('firstName', form.firstName),
      lastName: validateField('lastName', form.lastName),
      email: validateField('email', form.email),
      company: validateField('company', form.company),
      interest: '',
      message: '',
    };

    setErrors(foundErrors);
    if (Object.values(foundErrors).some(message => message)) {
      return;
    }
    try {
      const resp = await fetch(`${process.env.GATSBY_API_URL as string}/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...form, token }),
      });
      if (resp.ok) {
        setFormResult({
          type: 'success',
          message: 'Thank you, we will be in contact shortly!',
        });
      } else {
        setFormResult({
          type: 'error',
          message:
            'An error has occurred, please contact inquiries@ensemble.com directly or try again shortly',
        });
      }
    } catch (err) {
      setFormResult({
        type: 'error',
        message:
          'An error has occurred, please contact inquiries@ensemble.com directly or try again shortly',
      });
    } finally {
      refreshToken();
    }
  };

  return (
    <div
      data-testid="contact-form-container"
      className="flex flex-col md:max-w-lg max-w-md mx-auto mb-6 mt-8"
    >
      <h2 className="md:text-3xl md:font-extrabold text-2xl font-bold mb-1">
        {header}
      </h2>
      <p className="mt-1 md:text-base text-sm mb-5">
        <span className="inline">{description}</span>
      </p>
      <form onSubmit={onFormSubmit}>
        <div className="flex flex-col gap-y-3">
          <h4 className="md:font-semibold md:text-xl text-lg font-medium">
            {contactHeader}
          </h4>
          <div className="flex gap-x-3">
            {Object.entries(contactInputs)
              .slice(0, 2)
              .map(([key, { label, placeholder }]) => (
                <InputBase
                  key={key}
                  id={key}
                  required
                  label={label}
                  inline
                  error={errors[key as keyof typeof errors]}
                >
                  <Input
                    id={key}
                    onChange={onFieldChange}
                    placeholder={placeholder}
                  />
                </InputBase>
              ))}
          </div>
          {Object.entries(contactInputs)
            .slice(2, Object.entries(contactInputs).length)
            .map(([key, { label, placeholder }]) => (
              <InputBase
                key={key}
                id={key}
                required
                label={label}
                error={errors[key as keyof typeof errors]}
              >
                <Input
                  id={key}
                  onChange={onFieldChange}
                  placeholder={placeholder}
                />
              </InputBase>
            ))}
          <h4 className="md:font-semibold md:text-xl text-lg font-medium">
            {inquiryHeader}
          </h4>
          <InputBase id="interest" label={inquiryInputs.interest.label}>
            <select
              name="interest"
              id="interest"
              className={`appearance-none w-full bg-white border rounded border-gray-400 md:p-3 p-2 md:text-base text-sm bg-chevron bg-no-repeat bg-center-right-15px ${
                selectChanged ? 'text-black' : 'text-gray-400'
              } focus:outline-primary`}
              onChange={onFieldChange}
            >
              <option value="" disabled selected hidden>
                {inquiryInputs.interest.placeholder}
              </option>
              {inquiryInputs.interest.options?.map(option => (
                <option key={option} value={option} className="text-black">
                  {option}
                </option>
              ))}
            </select>
          </InputBase>
          <InputBase id="message" label={inquiryInputs.message.label}>
            <textarea
              name="message"
              id="message"
              onChange={onFieldChange}
              rows={4}
              className="block rounded border border-gray-400 appearance-none resize-y md:p-3 p-2 w-full md:text-base text-sm placeholder:text-gray-400 focus:outline-primary"
              placeholder={inquiryInputs.message.placeholder}
            />
          </InputBase>
          <p
            className={`${
              formResult.type === 'success' ? 'text-green-600' : 'text-error'
            }`}
            data-testid="result-message"
          >
            {formResult.message}
          </p>
        </div>
        <Button
          type="submit"
          variant="inverse"
          text="Submit"
          className={buttonClass}
          data-testid="submit-button"
          data-action="submit"
          data-sitekey={process.env.GATSBY_RECAPTCHA_PUB_KEY}
          data-callback="onFormSubmit"
        />
      </form>
    </div>
  );
}

export default ContactForm;
