import React from 'react';

type InputLabelProps = {
  id?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  placeholder?: string;
  variant?: 'default' | 'small' | 'large';
  error?: string;
  className?: string;
} & React.HTMLProps<HTMLInputElement>;

const Input = ({
  id,
  onChange,
  variant,
  placeholder,
  required,
  error,
  className,
  ...rest
}: InputLabelProps) => {
  return (
    <input
      className={`
        block 
        rounded 
        border 
        border-gray-400
        ${error ? 'border-error' : ''} 
        md:p-3
        p-2
        placeholder:text-gray-400
        md:text-base
        text-sm
        w-full
        focus:outline-primary
        ${className || ''}
      `}
      name={id}
      id={id}
      placeholder={placeholder}
      onChange={onChange}
      required={required}
      {...rest}
    />
  );
};

export default Input;
