import * as React from 'react';
import content from '../../content/contact.yaml';
import PageSection from '../../components/PageSection';
import Email from '../../components/contact/Email';
import Offices from '../../components/contact/Offices';
import Contacts from '../../components/contact/Contacts';
import ContactForm from '../../components/contact/ContactForm';
import DocumentHead from '../../components/DocumentHead';

const ContactPage = () => {
  return (
    <div data-testid="contact-page-container" className="md-900px:pt-9 pt-7">
      <DocumentHead content={content.metadata} />

      <PageSection
        className="md:mb-6 mb-5 mx-2.5 mt-4 md:mt-0 lg:mx-68px md:mx-64px"
        testId="form-container"
      >
        <ContactForm />
      </PageSection>

      <PageSection
        className="md:mb-6 mb-5 mx-2.5 mt-4 md:mt-0 lg:mx-68px md:mx-64px"
        testId="offices-container"
      >
        <Offices />
      </PageSection>

      <PageSection className="md:mb-10 mb-7 bg-background" testId="contacts">
        <Contacts />
      </PageSection>

      <PageSection className="md:mb-12 mb-10" testId="email">
        <Email />
      </PageSection>
    </div>
  );
};

export default ContactPage;
